<template>
    <template v-if="field.inputEditorConfig">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <OEditorItem :item="inputEditorItem" v-model="model" addFormClasses />
        </div>
    </template>
    <template v-else-if="field.type == 'text'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>

            <div v-if="field.useRichText ?? field.UseRichText">
                <slot name="richTextEditor" :value="model">
                    <ORichTextEditor
                        style="min-height: 65px; max-height: 250px; "
                        v-model="model"
                        :editMode="false"
                        :hideEditButton="readonly"
                    />
                </slot>
            </div>
            <input v-else-if="field.element == 'input'" v-model="model" class="form-control" type="text" :disabled="readonly">
            <OTextArea v-else-if="field.element == 'textarea'" v-model="model" class="form-control" :rows="field.textareaRows" :disabled="readonly" />
            <OContentEditable v-else-if="field.element == 'div'" v-model="model" class="form-control" :disabled="readonly" />
        </div>
    </template>
    <template v-else-if="field.type == 'number'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <input v-model="model" type="number" class="form-control" :disabled="readonly" />
        </div>
    </template>
    <template v-else-if="field.type == 'datetime'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <ODatePicker v-model="model" class="form-control" :disabled="readonly" />
        </div>
    </template>
    <template v-else-if="field.type == 'date'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <ODatePicker v-model="model" format="Short Date" date class="form-control" :disabled="readonly" />
        </div>
    </template>
    <template v-else-if="field.type == 'time'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <ODatePicker v-model="model" timepickerOnly class="form-control" :disabled="readonly" />
        </div>
    </template>
    <template v-else-if="field.type == 'radio'">
        <div :class="field.cssClass">
            <label class="d-block" :title="field.title">{{ field.caption }}</label>

            <label v-for="value in field.values" class="me-2">
                <input type="radio" v-model="model" :value="value.Value" :disabled="readonly">
                {{ value.Text }}
            </label>    
        </div>
    </template>
    <template v-else-if="field.type == 'select'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <OSelect v-model="model" class="form-select" :disabled="readonly">
                <option v-for="value in field.values" :value="value.Value">{{ value.Text }}</option>
            </OSelect>
        </div>
    </template>
    <template v-else-if="field.type == 'checkbox'">
        <div :class="field.cssClass">
            <label :title="field.title">
                <input v-model="model" type="checkbox">
                {{ field.caption }}
            </label>
        </div>
    </template>
    <template v-else-if="field.type == 'lookup'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <ODataLookup :data="field.values" class="form-control" :gridOptions="{noHeaderRow: true}" :value="model"
                :bind="sel => model=sel.Value" :disabled="readonly">
                <OColumn field="Text" />
            </ODataLookup>
        </div>
    </template>
    <template v-else-if="field.type == 'content'">
        <component :is="field.element" :title="field.title"  :class="field.cssClass">
            {{ field.caption }}
        </component>
    </template>
    <template v-else-if="field.type == 'multiSelect'">
        <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>
            <ODataLookup :data="field.values" class="form-control" :gridOptions="{noHeaderRow: true}" multiselect :value="multiSelectValue"
                :bind="sel => multiSelectValue = sel" :disabled="readonly" bindOnSelect>
                <OColumn field="Text" />
            </ODataLookup>
        </div>
    </template>
    <template v-else-if="field.type == 'multiCheckboxSelect'">
         <div :class="field.cssClass">
            <label :title="field.title">{{ field.caption }}</label>

            <label v-for="value in field.values" class="d-block">
                <input type="checkbox" v-model="multiCheckboxValue" :value="value.Value" :disabled="readonly">
                {{ value.Text }}
            </label>    
        </div>
    </template>
</template>

<script setup lang="ts">
import { useAsyncComponent } from 'o365-vue-utils';
import { computed } from  'vue';
import ORichTextEditor from 'o365.libraries.vue.components.RichTextEditor.vue';

export interface IProps {
    field: JSONDefinition['fields'][0],
    readonly?: boolean,
};


const OTextArea = useAsyncComponent('OTextArea', {
    importFn: () => import('o365-ui-components').then(comp => comp.OTextArea)
});
const OContentEditable = useAsyncComponent('OContentEditable', {
    importFn: () => import('o365-ui-components').then(comp => comp.OContentEditable)
});
const ODatePicker = useAsyncComponent('ODatePicker', {
    importFn: () => import('o365-ui-components').then(comp => comp.ODatePicker)
});
const OSelect = useAsyncComponent('OSelect', {
    importFn: () => import('o365-ui-components').then(comp => comp.OSelect)
});
const ODataLookup = useAsyncComponent('ODataLookup', {
    importFn: () => import('o365-datalookup').then(comp => comp.ODataLookup)
});
const OEditorItem = useAsyncComponent('OEditorItem', {
    importFn: () => import('o365-inputeditors').then(comp => comp.OEditorItem)
});


const props = defineProps<IProps>();
console.log(props);
const model = defineModel<any>();
const multiCheckboxValue = computed({
    get() {
        return model.value?.split('|').filter(x => !!x) ?? [];
    },
    set(val) {
        model.value = val.join('|');
    }
});
const multiSelectValue = computed({
    get() {
        return model.value?.split(', ').filter(x => !!x) ?? [];
    },
    set(val) {
        model.value = val.map(sel=>sel.Text).join(', ');
    }
});

const inputEditorItem = computed(() => {
    return {
        Config: props.field.inputEditorConfig,
        Readonly: props.readonly,
    }
});
</script>

<script lang="ts">
export type JSONLookupValue = {
    Value: string,
    Text: string
}

export type JSONFieldType = {
    type: 'text',
    element: 'input'|'textarea'|'div'
} | {
    type: 'number',
    element: undefined,
} | {
    type: 'datetime',
    element: undefined,
} | {
    type: 'time',
    element: undefined,
} | {
    type: 'date',
    element: undefined,
} | {
    type: 'checkbox',
    element: undefined,
} | {
    type: 'radio',
    element: undefined,
} | {
    type: 'select',
    element: undefined,
} | {
    type: 'lookup',
    element: undefined,
} | {
    type: 'content',
    element: 'p'|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'span'|'div',
} | {
    type: 'multiSelect',
    element: undefined,
} | {
    type: 'multiCheckboxSelect',
    element: undefined,
};

export type JSONDefinition = {
    fields: ({
        name: string,
        title: string,
        caption: string,
        row: number,
        cssClass: string,
        required: boolean,
        maxLength: number,
        col: number,
        colClass: string,
        values?: JSONLookupValue[],
        mergeIntoDescription: boolean,
        textareaRows?: number,
        UseRichText: boolean,
        useRichText: boolean,
        inputEditorConfig?: {

        },
        inputEditor?: string,
    } & JSONFieldType)[]  
};
</script>